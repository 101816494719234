<template>
  <div>
    <div class="changebox">
      <div
        class="changeitem"
        v-for="item in changetypelist"
        @click="changeclick(item.id)"
        :key="item.id"
        :class="{ accahngeitem: item.id == actypeindex }"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="tablelist">
      <div class="tablebox" v-show="actypeindex == 1">
        <div class="table-title">
          <div style="flex-grow: 1">时间</div>
          <div
            class="titleitem"
            @click="typeclick(1)"
            :class="{ actitle: titleindex == 1 }"
          >
            类型
            <div
              class="triangle-down"
              :class="{ 'actriangle-down': titleindex == 1 }"
            ></div>
          </div>
          <div
            class="titleitem"
            @click="typeclick(2)"
            :class="{ actitle: titleindex == 2 }"
          >
            金额变动
            <div
              class="triangle-down"
              :class="{ 'actriangle-down': titleindex == 2 }"
            ></div>
          </div>
          <div class="titleitem">账户余额</div>
        </div>
        <div class="seleltbox" v-if="isselelt">
          <div class="seleltcenter" v-if="titleindex == 1">
            <div
              class="seletitem"
              v-for="(item, i) in categorylist"
              :class="{ acseletitem: item.id == categoryindex }"
              :key="i"
              @click="changecategory(item.id, item.category)"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="seleltcenter" v-if="titleindex == 2">
            <div
              class="seletitem"
              v-for="(item, i) in typelist"
              :class="{ acseletitem: item.id == typeindex }"
              :key="i"
              @click="changetype(item.id, item.type)"
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="table-body">
          <div
            class="listitem"
            v-for="(item, i) in changelist"
            :class="(i * 1 + 1) % 2 == 0 ? 'one' : 'two'"
            :key="i"
          >
            <div style="flex-grow: 1" class="timeitem">
              {{ item.created_at }}
            </div>
            <div class="item">{{ item.category_alias }}</div>
            <div class="item">{{ item.bean }}</div>
            <div class="item">{{ item.final_bean }}</div>
          </div>
        </div>
      </div>
      <!-- 提取记录 -->
      <div class="tablebox" v-show="actypeindex == 2">
        <div class="table-title">
          <div class="titleitem t2">时间</div>
          <div class="titleitem t2">饰品名称</div>
          <div class="titleitem t2">状态</div>
        </div>

        <div class="table-body">
          <div
            class="listitem"
            v-for="(item, i) in changelist"
            :class="(i * 1 + 1) % 2 == 0 ? 'one' : 'two'"
            :key="i"
          >
            <div class="timeitem i2">
              {{ item.created_at }}
            </div>
            <div class="item i2">{{ item.bean }}</div>
            <div class="item i2">{{ item.final_bean }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagebox" v-show="actypeindex == 1">
      <Pagination
        :total="total"
        :page.sync="page"
        :limit.sync="per_page"
        @pagination="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { Chargerecord } from "../../network/api";
export default {
  data() {
    return {
      changetypelist: [
        {
          id: 1,
          title: "消费记录",
        },
        {
          id: 2,
          title: "提取记录",
        },
        {
          id: 3,
          title: "VIP记录",
        },
      ],
      actypeindex: 1,
      changelist: [],
      isselelt: false,
      titleindex: null,

      categorylist: [
        {
          id: 3,
          title: "全部",
          category: null,
        },
        {
          id: 1,
          title: "T币",
          category: 1,
        },
        {
          id: 2,
          title: "钻石",
          category: 2,
        },
      ],
      typelist: [
        {
          id: 3,
          title: "全部",
          type: null,
        },
        {
          id: 1,
          title: "收入",
          type: 1,
        },
        {
          id: 2,
          title: "支出",
          type: 0,
        },
      ],
      categoryindex: 3,
      category: null,
      type: null,
      typeindex: 3,

      page: 1,
      total: 10,
      per_page: 15,
    };
  },
  created() {
    this.getChargerecord();
  },
  methods: {
    handleCurrentChange(e) {
      this.page = e.page;
      this.getChargerecord();
    },
    changetype(v, i) {
      this.typeindex = v;
      this.type = i;
      this.isselelt = !this.isselelt;
      if (this.isselelt == false) {
        this.titleindex = null;
      }
      this.getChargerecord();
    },
    changecategory(v, i) {
      this.categoryindex = v;
      this.category = i;
      this.isselelt = !this.isselelt;
      if (this.isselelt == false) {
        this.titleindex = null;
      }
      this.getChargerecord();
    },
    typeclick(v) {
      this.titleindex = v;
      this.isselelt = !this.isselelt;
      if (this.isselelt == false) {
        this.titleindex = null;
      }
    },
    changeclick(v) {
      this.actypeindex = v;
    },
    async getChargerecord() {
      let params = {
        page: this.page,
        category: this.category,
        type: this.type,
      };
      const res = await Chargerecord(params);
      this.changelist = res.data.data.data;
      this.total = Number(res.data.data.total);
      this.per_page = Number(res.data.data.per_page);
    },
  },
};
</script>

<style lang="scss" scoped>
.changebox {
  width: 3.5rem;
  height: 0.38rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #161514;
  .changeitem {
    width: 0.96rem;
    height: 0.3rem;
    background: url("../../assets/newImages/record-table.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 0.12rem;
    color: #b79f79;
  }
  .accahngeitem {
    background: url("../../assets/newImages/record-actable.png") no-repeat;
    background-size: 100% 100%;
    color: #ffffff;
  }
}
.tablelist {
  width: 3.5rem;
  margin: 0.15rem auto 0;
  .tablebox {
    width: 100%;
    .table-title {
      width: 100%;
      height: 0.44rem;
      background: #1d1300;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 0.12rem;
      color: #ffffff;
      padding-left: 0.05rem;
      .titleitem {
        width: 23%;
        text-align: center;
        position: relative;
      }
      .t2 {
        width: 33%;
      }
      .triangle-down {
        position: absolute;
        right: 0;
        top: 0.04rem;
        width: 0;
        height: 0;
        border-left: 0.06rem solid transparent; /* 左边透明 */
        border-right: 0.06rem solid transparent; /* 右边透明 */
        border-top: 0.06rem solid #ffffff; /* 顶部颜色 */
      }
      .actitle {
        color: #edd76b;
      }
      .actriangle-down {
        border-top: 0.06rem solid #edd76b; /* 顶部颜色 */
      }
    }
    .seleltbox {
      width: 100%;
      height: 0.54rem;
      background: #1d1300;

      .seleltcenter {
        width: 3.11rem;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .seletitem {
          width: 0.96rem;
          height: 0.3rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #332a19;
          font-weight: 400;
          font-size: 0.12rem;
          color: #a89265;
        }
        .acseletitem {
          background: #8e7440;
          color: #ffffff;
        }
      }
    }
    .table-body {
      width: 100%;
      .listitem {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 0.12rem;
        color: #ffffff;
        height: 0.44rem;
        padding-left: 0.05rem;
        .timeitem {
          white-space: nowrap;
        }
        .item {
          width: 25%;
          text-align: center;
        }
        .i2 {
          width: 33%;
        }
      }
      .one {
        background: rgba(55, 46, 29, 0.6);
      }
      .two {
        background: #1d1300;
      }
    }
  }
}
.pagebox {
  margin: 0.12rem 0;
}
</style>
